<template>
    <div>

        <Card>
            <template #title>
                <div class="flex justify-content-between ">
                    <div>
                       <span class=" text-xl text-blue-600">DOCENTE - CATEDRÁTICO: </span> <span class="text-xl"> {{ user.persona }}</span>  
                    </div>
                    <div class="flex align-content-center align-items-center ">
                        <strong class="text-xl text-blue-600 pr-2"> GESTIÓN:</strong>
                        <Dropdown v-model="gestion" :options="gestions" optionLabel="gestion_tipo_id" placeholder="seleccione"
                            class="" @change="getResolutions()" />
                    </div>
                </div>

             </template>
            <template #content>
                <div class="grid">
                    <div class="col-4 flex ">

                        

                        <div class="flex flex-column" v-if="gestion">
                            <strong  class="text-blue-600"> CARRERA:</strong>
                            <Dropdown v-model="career" :options="careers" optionLabel="carrera" placeholder="Select a Carrera"
                                class="" @change="getSubjects(career)" >
                                <template #value="slotProps">
                                    <div class="kdropdown-icon" v-if="slotProps.value">
                                        <div class="icon">
                                            <i class="duotone icon-content"></i>
                                        </div>
                                        <div class="align-items-center">
                                            <div>{{ slotProps.value.carrera  }}  </div>
                                            <small>
                                                <span class="text-color-secondary">Resolución Plan de Estudio:  </span> <strong
                                                        class="text-blue-600"> {{
                                                            slotProps.value.numero_resolucion }}</strong> - <span
                                                        :class="slotProps.value.intervalo_gestion === 'AÑO' ? 'text-green-700' : 'text-cyan-500'">{{
                                                            slotProps.value.intervalo_gestion }}
                                                </span>
                                            </small>
                                        </div>
                                    </div>
                                </template>
                                <template #option="slotProps">
                                    <div class="kdropdown-icon">
                                        <div class="icon">
                                            <i class="duotone icon-content"></i>
                                        </div>
                                        <div class="align-items-center">
                                            <div>{{ slotProps.option.carrera }}  </div>
                                            <small> <span class="text-color-secondary">Resolución Plan de Estudio:  </span> <strong
                                                    class="text-blue-600"> {{
                                                        slotProps.option.numero_resolucion }}</strong> - <span
                                                    :class="slotProps.option.intervalo_gestion === 'AÑO' ? 'text-green-700' : 'text-cyan-500'">{{
                                                        slotProps.option.intervalo_gestion }}</span></small>
                                        </div>
                                    </div>
                                </template>
                            </Dropdown>
                        </div>

                    </div>
                    <div class="col-8 flex justify-content-end">

                        <div v-if="operativo && (operativo.modalidad_id === 9 || operativo.modalidad_id === 10 )" class="pr-1">
                            <div>
                                <InlineMessage severity="info" >
                                        ARTICULO 18.- (PRUEBA DE RECUPERACION).
                                        <br>
                                        I. La prueba de recuperacion es una
                                        evaluacion practica elaborada sobre la base de todos los contenidos de una asignatura. La
                                        nota minima para acceder a esta prueba es de 40 puntos como promedio final de la gestion.
                                        <br>
                                        III. La nota de aprobacion de la prueba de recuperacion es de 61 puntos,
                                        independientemente de la calificacion obtenida en la mencionada prueba.                                
                                </InlineMessage>
                            </div>
                        </div>
                        <div>
                            <div class="btn-group" v-if="operativo">
                                <!-- {{ operativo }}   -->
                                <button type="button" :class="`btn btn-primary dropdown-toggle `" data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    v-tooltip.left="{ value: `<h5 class='text-white'>Gestion: ${operativo.gestion} </h5><h5 class='text-white'>Evento:${operativo.evento} </h5> `, escape: true, class: 'custom-error' }">
                                    <div class="kdropdown-button">
                                        <div class="kicon">
                                            <i class="duotone icon-pencil" v-if="operativo.id === operativo_edit.id"></i>
                                            <i class="duotone icon-eye-open" v-else></i>
                                        </div>
                                        <div class="kcontent">
                                            <strong style="font-size: 10px;"> {{ operativo.periodo }} - {{ operativo.gestion }}
                                            </strong>
                                            <strong> {{ operativo.evento }} </strong>
                                            <strong v-if="operativo.modalidad_id > 0"> {{ operativo.modalidad }} </strong>
                                            <!-- <strong v-else> Periodo: {{ operativo.periodo }}</strong> -->
                                        </div>
                                    </div>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                                    <li v-for="(periodo, index ) in periodos_ademicos" :key="index">
                                        <div class="dropdown-item kitem-list " @click="setOperativo(periodo)">
    
                                            <div class="kicon">
                                                <i class="duotone icon-pencil" v-if="periodo.id === operativo_edit.id"></i>
                                                <i class="duotone icon-eye-open" v-else></i>
                                            </div>
                                            <div class="kcontent">
                                                <span>{{ periodo.periodoTipo.periodo }} - {{ periodo.gestionTipo.gestion }} </span>
                                                <span>{{ periodo.eventoTipo.evento }}</span>
                                                <span v-if="periodo.modalidadEvaluacionTipo"> {{ periodo.modalidadEvaluacionTipo.modalidadEvaluacion }}</span>
                                                <!-- <span v-else>Periodo: {{ periodo.periodo }} </span> -->
                                            </div>
    
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- <Message :closable="false" v-if="operativo">
                    <strong> Gestion: {{ operativo.gestion }} </strong>   <br>
                  <strong> Evento: {{ operativo.evento }}  </strong> <br>
                  <strong v-if="operativo.modalidad_id>0"> Modalidad de Evaluacion: {{ operativo.modalidad }} </strong> 
                  <strong v-else> Periodo: {{ operativo.periodo }}</strong>
                </Message> -->
                        <!-- <InlineMessage severity="info" v-if="operativo">
                  <strong> Gestion: {{ operativo.gestion }} </strong>   <br>
                  <strong> Evento: {{ operativo.evento }}  </strong> <br>
                  <strong v-if="operativo.modalidad_id>0"> Modalidad de Evaluacion: {{ operativo.modalidad }} </strong> 
                  <strong v-else> Periodo: {{ operativo.periodo }}</strong>
                </InlineMessage> -->
                        <!-- {{ JSON.stringify(operativo) }} -->
                    </div>
                </div>

                <!-- {{ JSON.stringify(subject_carrer) }} -->
                <div class="grid pt-1">
                    <div class="responsive-teacher">
                        <!-- <div style="width: 400px;"> -->
                        <div class="responsive-teacher subject ">
                            <Panel header="Asignaturas" style="width: 100%;" >
                                <ul class="kmenu-list">
                                    <li v-for="(item, index) in subjects" :key="index"
                                        :class="`kitem-menu ${index === indexSelected ? 'kactive' : ''}`">
                                        <div class="icon">
                                            <div class="badge">
                                                {{ item.paralelo }}
                                            </div>
                                        </div>
                                        <div class="content">
                                            <div class="ktext">
                                                <span :class="item.periodo === 'Primer Semestre'?'text-xs text-indigo-500':'text-xs text-green-500' "> {{ `${item.periodo}/${item.gestion_tipo_id} ` }}  <span class="text-blue-500"> {{ item.turno }}</span>  </span>
                                                <span class="text-sm">{{ item.asignatura}} </span>
                                            </div>
                                            <div style="display: flex;flex-direction:column " >
                                                <span class="text-xs" style="display: flex; justify-content: end;padding-top: 2px; padding-bottom: 0px; padding-right: 5px; color: #187DE4;"  > {{  item.abreviacion }}</span>
                                           
                                                <div style="display: flex; width: 100px; " >
                                                    
                                                    <router-link  v-tooltip.left="{value: 'Registro de Notas'}" class="icon-button" :to="`/record_academic/${item.aula_id}/${item.carrera_autorizada_id}/0`" target="_blank" >
                                                        <div class="kicon-button" >
                                                            <i class='bx bxs-file-pdf'></i>
                                                        </div>
                                                    </router-link>                                                
                 
                                                    <div class="icon-button">
                                                        <div class="kicon-button" @click="getStudents(item, index)">
                                                            <i class="bx bxs-user-detail"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                           
                            </Panel>
                        </div>
                        <div class="responsive-teacher students">
                            <Panel :header="`${aula.abreviacion} - ${aula.asignatura} NOTAS ${operativo.modalidad}`" style="width: 100%;"
                                v-if="aula && operativo?.evento === 'Calificaciones' && operativo?.modalidad !== 'NOTA FINAL'">
    
                                <template #icons v-if="operativo.id === operativo_edit.id">
                                    <!-- <Button icon="pi pi-file-pdf" severity="danger" text raised @click="printReport()" /> &nbsp; -->
                                    <Button icon="pi pi-times" label="Cancelar" severity="secondary" text raised
                                        @click="ReturnHome()" /> &nbsp;
                                    <Button icon="pi pi-check" label="Guardar" severity="success" text raised :disabled="dialog"
                                        @click="saveNotes()" />
    
                                    <div
                                        style="display: flex; flex-direction: row; justify-content: space-between ; align-items: center; width: 100%;">
    
                                    </div>
    
                                </template>
                                <div class="kadd-horizontal-scroll">
                                    <table class="table">
                                        <thead>
                                            <th>CI</th>
                                            <th>APELLIDO PATERNO</th>
                                            <th>APELLIDO MATERNO</th>
                                            <th>NOMBRES</th>
                                            <th v-for="(note_type, index) in note_types" :key="index"> {{ note_type.nota }} </th>
    
    
                                        </thead>
                                        <tbody>
                                            <tr v-for="(student, index) in students" :key="index">
                                                <td>{{ student.carnet_identidad }}</td>
                                                <td>{{ student.paterno }}</td>
                                                <td>{{ student.materno }}</td>
                                                <td>{{ student.nombre }} </td>
                                                <td v-if="operativo.id === operativo_edit.id && operativo.modalidad_id !== 9 && operativo.modalidad_id !== 10  && operativo.periodo_tipo_id === itemSelected.periodo_tipo_id ">
                                                    <InputText type="number" :class="student.teorica > 30 ? 'p-invalid' : ''"
                                                        v-model="student.teorica" class="w-full md:w-10rem"
                                                        :update="subTotal(student)" /> <br>
                                                    <small class="p-error" id="text-error" v-if="student.teorica > 30">Esta nota
                                                        no puede ser mayor a 30</small>
                                    
                                                </td>
                                                <td v-else>
                                                    {{ student.teorica ?? 0 }}
                                                </td>
    
                                                <td v-if="operativo.id === operativo_edit.id && operativo.periodo_tipo_id === itemSelected.periodo_tipo_id ">
                                                    
                                                    <div v-if="operativo.modalidad_id === 9 || operativo.modalidad_id === 10">
                                                        
                                                        <InputText type="number" :class="student.practica > 100 ? 'p-invalid' : ''"
                                                            v-model="student.practica" class="w-full md:w-10rem"
                                                            :update="subTotal(student)" />
                                                        <br>
                                                        <small class="p-error" id="text-error" v-if="student.practica > 100">Esta nota
                                                            no puede ser mayor a 100</small>
                                                    </div>
                                                    <div v-else>
                                                        <InputText type="number" :class="student.practica > 70 ? 'p-invalid' : ''"
                                                            v-model="student.practica" class="w-full md:w-10rem"
                                                            :update="subTotal(student)" />
                                                        <br>
                                                        <small class="p-error" id="text-error" v-if="student.practica > 70">Esta nota
                                                            no puede ser mayor a 70</small>
                                                    </div>
                                                </td>
                                                <td v-else>
                                                    {{ student.practica }}
                                                </td>
                                                <td> {{ student.suma }}</td>
    
                                            </tr>
                                        </tbody>
                                    </table>
    
                                </div>
                            </Panel>
    
                            <Panel :header="`${aula.abreviacion} - ${aula.asignatura} ESTUDIANTES INSCRITOS`" style="width: 100%;"
                                v-if="aula && operativo?.evento === 'Inscripciones'">
    
                                <template #icons>
                                    <!-- <button class="p-panel-header-icon p-link mr-2" @click="printReport()">
                                    <span class="pi pi-cog"></span>
                                </button> -->
    
                                </template>
                                <table class="table">
                                    <thead>
                                        <th>CI</th>
                                        <th>APELLIDO PATERNO</th>
                                        <th>APELLIDO MATERNO</th>
                                        <th>NOMBRES</th>
    
                                    </thead>
                                    <tbody>
                                        <tr v-for="(student, index) in students" :key="index">
                                            <td>{{ student.carnet_identidad }}</td>
                                            <td>{{ student.paterno }}</td>
                                            <td>{{ student.materno }}</td>
                                            <td>{{ student.nombre }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Panel>
    
                            <Panel :header="`${aula.abreviacion} - ${aula.asignatura} NOTAS ${operativo.modalidad}`" style="width: 100%;"
                                v-if="aula && operativo?.evento === 'Calificaciones' && operativo?.modalidad === 'NOTA FINAL'">
                                <template #icons>
                                    <!-- <button class="p-panel-header-icon p-link mr-2" @click="printReport()">
                                    <span class="pi pi-cog"></span>
                                </button> -->
    
                                </template>
    
                                <div class="kadd-horizontal-scroll">
                                    <table class="table">
                                        <thead>
                                            <th>CI </th>
                                            <th>APELLIDO PATERNO</th>
                                            <th>APELLIDO MATERNO</th>
                                            <th>NOMBRES</th>
                                            <th v-for="(title, index) in modalities " :key="index"> {{ title.modalidadEvaluacion }} </th>
                                            <th>ESTADO FINAL</th>
    
                                        </thead>
                                        <tbody>
                                            <tr v-for="(student, index) in students" :key="index">
                                                <td>{{ student.carnet_identidad }}</td>
                                                <td>{{ student.paterno }}</td>
                                                <td>{{ student.materno }}</td>
                                                <td>{{ student.nombre }}</td>
                                                <td v-for="(total, index) in student.totales" :key="index">
                                                    {{ total.cuantitativa }}
                                                </td>
    
                                            </tr>
                                        </tbody>
                                    </table>
    
                                </div>
                            </Panel>
    
                        </div>

                    </div>

                </div>


            </template>
        </Card>
        <Dialog v-model:visible="dialog"  header="Registrando Notas " :modal="true" :closable="false">
            <div class="flex justify-content-center flex-column ">
                <ProgressSpinner />
            
            </div>
        </Dialog>
      

    </div>
</template>
<script>
import TeacherNoteDataService from '@/services/TeacherNoteDataService';
import CareerDataServie from '@/services/CareerDataServie';
import NoteTypeDataService from '@/services/NoteTypeDataService';
// import { jsPDF } from "jspdf";
// import 'jspdf-autotable'

// import '@/scss/wkhtml.scss'
import '@/scss/report.scss'
import _ from 'lodash';
export default {
    data: () => ({
        careers: [],
        career: null,
        subjects: [],
        subject_carrer: [],
        students: [],
        note_types: [],
        operativo: null,
        operativo_edit: null,
        aula: null,
        indexSelected: -1,
        itemSelected: null,
        periodos_ademicos: [],
        totales_title: [],
        modalities: [],
        dialog: false,
        disabled: false,
        url_print: '',
        gestion: null,
        gestions: [],
        // has_titles:false,
    }),
    mounted() {
        // this.getSubjects()
        this.getNoteTypeList()
        this.getGestions()

    },
    methods: {

        async getSubjects(career) {

            // TeacherNoteDataService.getSubjects(this.user.p_id)
            //     .then((response) => {
            //         console.log('subjects',response.data)
            //         this.subjects = response.data.data
            //         this.carers = this.groupByMake(response.data.data)
            //         // console.log(this.carers) 

            //     })
            console.log('career ',career)
            let payload = {
                persona_id: this.user.p_id,
                institucion_educativa_sucursal_id: this.institution.ie_sid,
                gestion_tipo_id: this.gestion.gestion_tipo_id,
                instituto_plan_estudio_carrera_id: career.instituto_plan_estudio_carrera_id
            }

            let response = await TeacherNoteDataService.getSubjectsByTeahcer(payload)
            console.log('subject',response.data)

            this.subjects = response.data
            this.getPeriodosAcademicos(career.carrera_autorizada_id)
            this.getOperativo(career.carrera_autorizada_id)
            this.getModalities(career.intervalo_gestion_tipo_id)
        },

        async getGestions(){
           let response = await TeacherNoteDataService.getGestionsByTeacher(this.user.p_id,this.institution.ie_sid)
           console.log('gestions', response.data)
           this.gestions = response.data
        },

        async getResolutions()
        {
            this.career = null
            this.subjects = []
            this.periodos_ademicos = []
            this.operativo = null

            let payload = {
                persona_id: this.user.p_id,
                institucion_educativa_sucursal_id: this.institution.ie_sid,
                gestion_tipo_id: this.gestion.gestion_tipo_id
            }

            let response = await TeacherNoteDataService.getResolutionsByTeahcer(payload)

            console.log('resolutions',response.data)
            this.careers = response.data
        },

        getModalities(id) {
            CareerDataServie.getEvaluationModalities(id)
                .then((response) => {
                    this.modalities = response.data
                    console.log('modalidades ------------------------------azs', response)

                })
        },
        getNoteTypeList() {
            NoteTypeDataService.getAll()
                .then((response) => {
                    this.note_types = response.data
                    console.log('type_notes', response.data)
                })
        },
        setOperativo(new_operativo) {
            this.operativo = {
                    gestion: new_operativo.gestionTipo.gestion ,
                    gestion_tipo_id: new_operativo.gestionTipo.id,
                    periodo: new_operativo.periodoTipo.periodo,
                    periodo_tipo_id: new_operativo.periodoTipo.id,
                    fecha_inicio: '',
                    fecha_fin: '',
                    observacion: '' ,
                    activo: new_operativo.activo,
                    id: new_operativo.id,
                    evento: new_operativo.eventoTipo.evento,
                    modalidad_id: new_operativo.modalidadEvaluacionTipoId,
                    modalidad: new_operativo.modalidadEvaluacionTipo.modalidadEvaluacion,
                    abreviacion: new_operativo.modalidadEvaluacionTipo.abreviacion
                }
            this.getStudents(this.itemSelected, this.indexSelected)
        },

        groupByMake(list) {

            let carreras = []

            list.forEach(element => {
                let carrera = _.find(carreras, (objeto) => { return objeto.carrera_autorizada_id === element.carrera_autorizada_id })
                if (!carrera) {
                    carreras.push({ carrera_autorizada_id: element.carrera_autorizada_id, nombre: element.carrera })
                }
            });

            return carreras
        },
        async getSubjectsByCarer(item) {
            // console.log(item)

            this.getOperativo(item.carrera_autorizada_id)
            this.students = []
            this.aula = null
            this.indexSelected = -1
            this.getPeriodosAcademicos(item.carrera_autorizada_id)
            let response = await CareerDataServie.get(item.carrera_autorizada_id)
            this.career = response.data.data
            console.log('response ......>',this.career)
        
            this.getModalities(this.career.intervalo_gestion_tipo_id)
            if (item) {
                this.subject_carrer = []
                this.subjects.forEach(element => {
                    if (element.carrera_autorizada_id === item.carrera_autorizada_id) {
                        this.subject_carrer.push(element)
                    }
                });

            }
        },
        getPeriodosAcademicos(career_id) {
            CareerDataServie.getPeriodosAcademicosGestion(career_id,this.gestion.gestion_tipo_id)
                .then((response) => {
                    this.periodos_ademicos = response.data.data
                    console.log('periodos academicos', response.data)

                })
        },
        subTotal(student) {
            student.suma = parseFloat(student.teorica ?? 0) + parseFloat(student.practica ?? 0)
        },
        async getStudents(aula, index) {
            this.itemSelected = aula
            console.log('item selected',this.itemSelected)
            this.indexSelected = index
            this.aula = aula
            if (this.operativo.modalidad === 'RECUPERATORIO' || this.operativo.modalidad === 'RECUPERATORIO EXCEPCIONAL' ) {
                let response = await TeacherNoteDataService.getStudentsRecovery(aula.aula_id, this.operativo.periodo_tipo_id)
                // console.log('estudiantes reprobados', response)
                this.students = response.data.data

            } else {

                let response = await TeacherNoteDataService.getStudents(aula.aula_id)
                this.students = response.data.data
                // console.log('estudiantes XD', response)
            }
            // console.log('estudiantes',this.students)
            // let s =_.orderBy(this.students,['paterno','materno'],['asc','asc'])
            // console.log(s)
            // this.students = s 
            if (this.operativo.evento === 'Calificaciones' && this.operativo.modalidad !== 'NOTA FINAL') {
                this.students.forEach(async student => {

                    let result = await TeacherNoteDataService.getStudentNoteModalidad(student.instituto_estudiante_inscripcion_id, this.operativo.modalidad_id)
                    // let notas = await TeacherNoteDataService.getStudentNote(student.instituto_estudiante_inscripcion_id)
                    console.log('result', result.data.data)

                    let practica = _.find(result.data.data, (objeto) => { return objeto.nota_tipo === 'PRÁCTICA' })
                    // console.log('nota_practica',practica)
                    let teorica = _.find(result.data.data, (objeto) => { return objeto.nota_tipo === 'TEORÍA' })

                    let suma = _.find(result.data.data, (objeto) => { return objeto.nota_tipo === 'SUMA' })

                    if (teorica) {
                        student.teorica = parseFloat(teorica.cuantitativa)
                    }

                    if (practica) {
                        student.practica = parseFloat(practica.cuantitativa)
                    }

                    if (suma) {
                        student.suma = parseFloat(suma.cuantitativa)
                    }

                    return student
                });
            }

            if (this.operativo.evento === 'Calificaciones' && this.operativo.modalidad === 'NOTA FINAL') {

                // console.log('renderizando nota final------------->')
                // console.log('modality', this.modalities)
                this.students.forEach(async student => {

                    // let result = await TeacherNoteDataService.getStudentNoteModalidad(student.instituto_estudiante_inscripcion_id,this.operativo.modalidad_id)
                    let result = await TeacherNoteDataService.getStudentNote(student.instituto_estudiante_inscripcion_id)
                    let notas = result.data.data
                    console.log('notas', result.data.data);
                    let totales = []

                    this.totales_title = []
                    let estado = ''
                    // console.log('modality', this.modalities)
                    this.modalities.forEach(modality => {
                        // console.log('modalidad', modality)
                        let nota = _.find(notas, (objeto) => { return objeto.nota_tipo === 'SUMA' && objeto.modalidad_id === modality.id })
                        // console.log( 'nota',nota)
                        if (nota) {
                            estado = nota.estado_final
                            totales.push({ modalidad_id: nota.modalidad_id, cuantitativa:  Math.round(nota.cuantitativa) })
                        } else {
                            totales.push({ modalidad_id: modality.id, cuantitativa: 0 })
                        }

                    });

                    totales.push({ modalidad_id: 0, cuantitativa: estado })
                    // notas.forEach(nota => 
                    // {
                    //     if(nota.nota_tipo === 'SUMA')                        
                    //     {
                    //         totales.push(nota)

                    //         this.totales_title.push({
                    //         name: nota.modalidad_evaluacion,
                    //         modalidad_id: nota.modalidad_id,
                    //     })
                    //     }
                    // });
                    
                    student.totales = totales

                    console.log('result', result.data.data)
                    console.log('notas', totales)
                    console.log('totales', this.totales_title)
                    // let practica = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'PRÁCTICA' } )
                    // // console.log('nota_practica',practica)
                    // let teorica = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'TEORÍA' } )

                    // let suma = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'SUMA' } )

                    // if(teorica)
                    // {
                    //     student.teorica = parseInt(teorica.cuantitativa) 
                    // }

                    // if(practica)
                    // {
                    //     student.practica = parseInt( practica.cuantitativa)
                    // }

                    // if(suma)
                    // {
                    //     student.suma = parseInt(suma.cuantitativa) 
                    // }

                    return student
                });
            }

            // console.log(response.data.data)

        },
        getOperativo(carrera_autorizada_id) {
            this.operativo = null
            this.operativo_edit = null
            CareerDataServie.getOperativoCarrera(carrera_autorizada_id)
                .then((response) => {
                    this.operativo = response.data.data
                    this.operativo_edit = response.data.data
                    console.log('operativo', response.data.data)

                })
        },
        cancelNotes() {

        },
        async saveNotes() {
            this.dialog = true ;
            let payload = []
            let teorica = _.find(this.note_types, (objeto) => { return objeto.nota === 'TEORÍA' })
            let practica = _.find(this.note_types, (objeto) => { return objeto.nota === 'PRÁCTICA' })
            console.log('teoria', teorica)
            console.log('practica', practica)
            
            let valid = true  //variable de control de notas adicionar logica al for 
            this.students.forEach(student => {

                let student_payload =  {
                                            aula_docente_id: student.aula_docente_id,
                                            aula_id: this.aula.aula_id,
                                            instituto_estudiante_inscripcion_id: student.instituto_estudiante_inscripcion_id,
                                            periodo_tipo_id: this.operativo.periodo_tipo_id,
                                            // cuantitativa: parseFloat(student.teorica),
                                            // nota_tipo_id: teorica.id,
                                            modalidad_evaluacion_tipo_id: this.operativo.modalidad_id,
                                            notes:[]
                                        }

                //para el teorico
                valid = true
                if (student.teorica) {

                    if(student.teorica >= 0 && student.teorica <= 30 )
                    {  
                         
                        if(valid)
                        {
                            student_payload.notes.push({
                                cuantitativa: parseFloat(student.teorica),
                                nota_tipo_id: teorica.id,
                            })   
                        }
  
                    }else {

                        valid = false
                        this.$toast.add({ severity: 'error', summary: 'Error Nota Teorica Estudiante CI '+student.carnet_identidad, detail:`No se pudo registrar nota del estudiante ${student.paterno ?? ''} ${student.materno ?? ''} ${student.nombre ?? ''} `, life: 5000 });
                    }

                }

                //para el practico

                if (student.practica) {

                    if(this.operativo.modalidad_id === 9 || this.operativo.modalidad_id === 10)
                    {
                        if(student.practica >= 0 && student.practica <= 100 )
                        {
                            if(valid) {
                                student_payload.notes.push({
                                    cuantitativa: parseFloat(student.practica),
                                    nota_tipo_id: practica.id,
                                })
                            }
                        }else {
                            valid = false
                            this.$toast.add({ severity: 'error', summary: 'Error Nota Practica Estudiante CI '+student.carnet_identidad, detail:`No se pudo registrar nota del estudiante ${student.paterno ?? ''} ${student.materno ?? ''} ${student.nombre ?? ''} `, life: 5000 });
                        }


                    }else{

                        if(student.practica >= 0 && student.practica <= 70 )
                        {
                            if(valid) {
                                student_payload.notes.push({
                                    cuantitativa: parseFloat(student.practica),
                                    nota_tipo_id: practica.id,
                                })
                            }
                        }else {
                            valid = false
                            this.$toast.add({ severity: 'error', summary: 'Error Nota Practica Estudiante CI '+student.carnet_identidad, detail:`No se pudo registrar nota del estudiante ${student.paterno} ${student.materno} ${student.nombre} `, life: 3000 });
                        }
                    }
                   
                }

                if(valid)
                {
                    payload.push(student_payload)
                }

            });
            console.log('payload students',payload)
            let response = await TeacherNoteDataService.saveNotes(payload)
            console.log(response.data)
            if (response.data.status === 200)
            {
                this.$toast.add({ severity: 'success', summary: 'Registro de Notas', detail: `${response.data.message}`, life: 3000 });
                this.dialog = false
            }

            if (response.data.status === 500)
            {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: `${response.data.message}`, life: 5000 });
                this.dialog = false
            }
            // await new Promise(r => setTimeout(r, 700));
                // this.$store.dispatch('showMessage',{severity:'success',title:'Registro de Notas',message:'Se registraron las notas '})


            // if (response.data.statusCode === 201) {
            //     // window.location.reload()
            //     this.students = []
            //     this.aula = null
            // //     this.itemSelected = aula
            // // this.indexSelected = index
            //     // this.getStudents(this.itemSelected, this.indexSelected)
                
            //     // this.operativo = null
            //     await new Promise(r => setTimeout(r, 700));
            //     // this.$store.dispatch('showMessage',{severity:'success',title:'Registro de Notas',message:'Se registraron las notas '})
            //     this.$toast.add({ severity: 'success', summary: 'Registro de Notas', detail: 'Se registraron las notas ', life: 3000 });
            //     this.dialog = false
                
            // }

            // calculando promedio segun cris
            //response = await TeacherNoteDataService.averageCalculation(this.aula.aula_id, this.operativo.periodo_tipo_id)

            //calculando los estados segun cris

            // response = await TeacherNoteDataService.averageState(this.aula.aula_id)

        },
     
        ReturnHome() {
            console.log('retornando XD')
            this.$router.push('/')
        }
    },
    computed: {
        user() {
            return this.$store.state.auth.user
        },
        institution() {
            return this.$store.state.auth.institution
        }
    }
}
</script>
